body {
  background: #23272a;
}
.accounts-cards {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.accounts-cards.middle {
  place-items: center;
}
.account-card {
  background-color: #2c2f33;
  border-radius: 10px;
  color: #f2f3f5;
  padding: 1rem;
  transition: all 0.3s;
  cursor: pointer;
}
.account-card a button {
  border-color: #6a5acd;
  background: #6a5acd;
}
.account-card a:hover button {
  border-color: #5448a3;
  background: #5448a3;
}
.account-card:hover {
  background-color: #272a2e;
  /* color: #3a3d43; */
}
.account-card.female-bg {
  /* background: #ff69b4; */
  background: #E778F0;
}
.account-card.female-bg a button {
  background: #fff;
  border-color: #fff;
  color: #272a2e;
}
.account-card.female-bg a:hover button {
  background: #d3d3d3;
  border-color: #d3d3d3;
}
.account-card.female-bg:hover {
  background: #af5ab5;
}
.account-card h3 {
  font-size: 1.2rem;
}
.account-card img {
  width: 100%;
}
.additional-card-details {
  width: 100%;
  background-color: #3a3d43;
  border-radius: 10px;
  padding: 1rem;
}
.additional-card-details h2 {
  color: #f2f3f5;
}
.additional-card-details h3 {
  color: #f2f3f5;
}
.main-text-color {
  color: #f2f3f5;
}
.account-details-boxes {
  display: flex;
  gap: 30px;
}
.account-details-boxes button {
  height: 100px;
  width: 100px;
  color: #f2f3f5;
  background-color: #3a3d43;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .account-details-boxes {
    flex-direction: column;
  }
}

/* modal  */
.modal-content {
  background-color: #3a3d43;
}
.modal-content .modal-body h3 {
  color: #f2f3f5;
}
.modal-content .modal-body h4 {
  color: #f2f3f5;
}
.modal-content .modal-header h5 {
  color: #f2f3f5;
}
.modal-content .modal-body p {
  color: #f2f3f5;
}
.modal-content .modal-header button {
  background: transparent;
  border: none;
  color: white;
  font-size: 25px;
}
.xp-modal-section {
  border-radius: 10px;
  background-color: #787373db;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.modal {
  background-color: #787373db;
  --bs-modal-width: 80%;
}
.xp-modal {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 20px;
}

/* accordion */
.accordion.accordion-flush .accordion-header .accordion-button,
.accordion.accordion-flush .accordion-item {
  background: #2c2f33;
}
.accordion-item .accordion-button,
.accordion-button:not(.collapsed) {
  color: #f2f3f5;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #464646;
}
.accordion-item {
  border-color: #23272a;
}
.accordion-body hr {
  color: #f2f3f5;
}

.accordion-body .progress {

  --bs-progress-bg: #23272a;
}
.accordion-body .progress-bar {
  background: #4B0082;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}


.form-control:disabled {
  background-color: #23272a;
  color: #f2f3f5;
  border-color: #23272a;
}
.items-details-container .form-control{
  background-color: #2C2F33;
  color: #f2f3f5;
  border-color: #23272a;
  
}
 
.scrollable-container{
  /* max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll; */
}
.scrollable-container > div{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
  /* grid-template-columns: repeat(3,1fr); */
}

.scrollable-container .card.account-card .badge{
  background: #4B0082;
}
.scrollable-container .card.account-card .badge.secondary{
  background: #BA55D3;
}
.scrollable-container .card.account-card .gap{
  gap: 0.5rem;
}
 .card.account-card img{
  object-fit: contain;
}

.accordion .badge.secondary{
background: #4B0082;
padding-inline: 1rem;
}

.items-details-container select {
  background: #2c2f33;
  border: none;
  color: #f2f3f5;
  padding: .5rem;
}

.selected{
  border: 1px solid white;
}

.card.account-card img{
  height: 100px;
}

.account-card .card-title{
  font-size: 0.8rem;
}

.card-body{
  padding: unset;
}

.account-card{
  width: fit-content;
}

.accordion-button::after{
   filter: brightness(99);
}

.pointer{
  cursor: pointer;
}

.active-sort-color{
   color: #BA55D3;
}

.fixed-buy-btn{
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 9999999;
}

.fixed-buy-btn button{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.card-title{
  text-align: center;
}

@media screen and (max-width: 768px) {
    .fixed-buy-btn {
      position: fixed;
      bottom: 50%;
      right: -8px;
      z-index: 9999999;
    }

    .fixed-buy-btn button{
       font-size: 0.8rem;
       width: 60px;
       height: 60px;
       border-radius: 10px;
    }

  .account-card{
      max-width: 165px;
    }

    .hide-on-mobile{
       display: none;
    }

    .card.account-card img{
       height: 60px;
    }

    .card-title{
        font-size: 1rem !important;
    }

    .card-body{
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
    }

    .item-card{
        width: 100% !important;
        max-width: initial !important;
    }

    .scrollable-container > div{
       grid-template-columns: 1fr 1fr;
    }

    .badge{
      text-wrap: pretty;
    }
}
.pagination-card {
  background: #2c2f33;
  cursor: pointer;
  padding: 0.75rem   ;
  display: flex;align-items: center;
  border-radius: .25rem;
  border: 1px solid #d3d3d3;
}
.pagination-card:hover {
  background: #3a3d43;
}
.pagination-card:disabled{
  cursor: not-allowed;
}